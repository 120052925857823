import React from "react";

import NavAnchor from "./NavAnchor";


const Hero = () => {

  const jumpToDiv = (id) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();

      const releventDiv = document.getElementById(id);
      // behavior: "smooth" parameter for smooth movement
      releventDiv.scrollIntoView({ behavior: "smooth" });
    };
  };

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <div id="banner-wrapper">
      <div id="banner" className="box">
        <div className="row">
          <div className="col-7 col-12-medium">
            <h2>Let's Color!</h2>
            <p>Coloring books your kids will never forget</p>
          </div>
          <div className="col-5 col-12-medium">
            <ul>
              <li><a href="#" onClick={jumpToDiv("free-downloads")} className="button  large icon solid fa-download">Free Books</a></li>
              <li><NavAnchor path="/studio" className="button alt2 large icon solid fa-arrow-circle-right">Make One!</NavAnchor></li>
              <li><a href="#" onClick={jumpToDiv("about")} className="button alt large icon solid fa-question-circle">More info</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
  /* eslint-enable jsx-a11y/anchor-is-valid */
};

export default Hero;
