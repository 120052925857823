import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const onFirebaseLoadingCompleted = createAsyncThunk(
  "auth/onFirebaseLoadingCompleted",
  async ({ user }) => {
    if (user) {
      return {
        user: {
          email: user.email,
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          displayName: user.displayName
        },
        token: user.accessToken,
      };
    }
    return {
      user: null,
      token: null,
    };
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: true,
    user: null,
    token: null,
  },
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(onFirebaseLoadingCompleted.pending, (state) => {
    });

    builder.addCase(onFirebaseLoadingCompleted.fulfilled, (state, { payload }) => {
      const { user, token } = payload;
      state.user = user;
      state.token = token;
    });
  },
});

export default authSlice.reducer;
