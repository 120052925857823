import React, { Fragment } from "react";
import { useParams } from 'react-router-dom';


import Download from "../components/Download";

const DownloadView = () => {

  const { code } = useParams(); // Get the code from the URL

  return (
    <Fragment>
      <Download code={code} />
    </Fragment>
  );
};

export default DownloadView;
