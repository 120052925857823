import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { signInWithPopup } from "firebase/auth";

import { onFirebaseLoadingCompleted } from "../redux/auth/slice";
import { auth, googleProvider } from "../config/firebase";
import { useDispatch } from 'react-redux';
import { isInAppBrowser, getOS } from '../utils/browser';

const SignInModal = ({ isOpen, toggle, message = 'You need to sign in to continue.' }) => {

  const isInEmbeddededBrowser = isInAppBrowser();
  const dispatch = useDispatch();

  const handleGoogleSignIn = async () => {
    // Start the Google sign-in redirect flow
    const signIn = await signInWithPopup(auth, googleProvider);
    dispatch(onFirebaseLoadingCompleted(signIn));
    if (!signIn) {
      // console.log("login failed")
    }
    // console.log(signIn);
    toggle();
  };

  const clientOS = getOS();



  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Join / Sign in</ModalHeader>
      {!isInEmbeddededBrowser ?

        <ModalBody>
          <p>{message}</p>
          <div className="d-flex justify-content-center">
            {/* Example Sign In Options */}
            <Button color="primary" onClick={handleGoogleSignIn} className='button'>
              Sign in with Google
            </Button>
            {/* <Button color="secondary" onClick={() => console.log('Sign in with Facebook')}>
            Sign in with Facebook
          </Button> */}
          </div>
        </ModalBody>
        :
        <ModalBody>
          <h5>Embedded Browser Not Supported</h5>
          <p>
            It looks like you're using Instagram or another app's browser, which is not supported for signing in for security reasons.
            Please open this page in your default mobile browser (Safari, Chrome, etc.) and try again.<br></br>
            If using Instagram, please tap the three dots (•••) in the top right corner and choose 'Open in Browser' to continue.
          </p>
        </ModalBody>
      }
      <ModalFooter>
        {isInEmbeddededBrowser && clientOS === "Android" &&
          <a href='https://kyoko.ink' download className='button'>Open in Browser</a>}
        {isInEmbeddededBrowser && clientOS === "iOS" &&
          <a href={`shortcuts://x-callback-url/run-shortcut?name=${crypto.randomUUID()}&x-error=${encodeURIComponent('https://kyoko.ink')}`} className='button'>Open in Browser</a>}
        <Button color="secondary" onClick={toggle}
          className='button alt'>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SignInModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default SignInModal;
