import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    audioPanel: {
      isOpen: false,
      targetTrackId: null,
      activeTrackId: null,
      isPlaying: false,
      trackMetadataById: {},
    },
  },
  reducers: {
    onAudioLinkClicked: (state, { payload }) => {
      const { targetTrackId } = payload;
      state.audioPanel.targetTrackId = targetTrackId;
      state.audioPanel.activeTrackId = targetTrackId;
      state.audioPanel.isPlaying = true;
    },
    onAudioPanelSwipedRight: (state) => {
      state.audioPanel.isOpen = false;
      state.audioPanel.targetTrackId = null;
    },
    onAudioPanelSwipedLeft: (state) => {
      state.audioPanel.isOpen = true;
      state.audioPanel.targetTrackId = null;
    },
    onPlayTrackToggled: (state, { payload }) => {
      const { trackId } = payload;
      state.audioPanel.targetTrackId = null;

      if (state.audioPanel.activeTrackId === trackId) {
        state.audioPanel.isPlaying = !state.audioPanel.isPlaying;
      } else {
        state.audioPanel.activeTrackId = trackId;
        state.audioPanel.isPlaying = true;

        if (!state.audioPanel.trackMetadataById[trackId]) {
          state.audioPanel.trackMetadataById[trackId] = {
            id: trackId,
            currentTime: 0,
            duration: 0,
          };
        }
      }
    },
    onTrackCurrentTimeUpdated: (state, { payload }) => {
      const { trackId, time } = payload;
      if (!state.audioPanel.trackMetadataById[trackId]) {
        state.audioPanel.trackMetadataById[trackId] = {
          id: trackId,
          currentTime: time,
          duration: 0,
        };
      }

      state.audioPanel.trackMetadataById[trackId] = {
        ...state.audioPanel.trackMetadataById[trackId],
        currentTime: time,
      };
    },
    onTrackDurationUpdated: (state, { payload }) => {
      const { trackId, duration } = payload;
      if (!state.audioPanel.trackMetadataById[trackId]) {
        state.audioPanel.trackMetadataById[trackId] = {
          id: trackId,
          currentTime: 0,
          duration: duration,
        };
      }
      state.audioPanel.trackMetadataById[trackId] = {
        ...state.audioPanel.trackMetadataById[trackId],
        duration,
      };
    },
  },
});

export const {
  onAudioLinkClicked,
  onAudioPanelSwipedRight,
  onAudioPanelSwipedLeft,
  onPlayTrackToggled,
  onTrackCurrentTimeUpdated,
  onTrackDurationUpdated,
} = uiSlice.actions;

export default uiSlice.reducer;
