import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { selectAuthToken } from '../redux/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { checkPurchaseSuccess } from '../api';
import { onGetProfile } from '../redux/profile/slice';
import { Col, Button } from 'reactstrap';

import pineappledance from '../assets/images/pinyahappydance.gif';
import sadface from '../assets/images/paymentfailfaces.gif'
import "./Payment.scss"

// Components for success and failure messages
const PaymentSuccess = ({ navigateToStudio }) => (
  <Col className='success-container text-center'>
    <h2>Payment Success!</h2>
    <img id="successImage" src={pineappledance} alt="Celebrating the Download" />
    <h3>Let's Get Coloring</h3>
    <Button onClick={navigateToStudio}>GO!</Button>
  </Col>
);

const PaymentFailure = ({ order_ref }) => (
  <Col className="failure-container text-center">
    <h2>Payment Failed</h2>
    <img src={sadface} alt="Sad Because of Failure" />
    <h3>Oops! It seems there was an issue processing your payment.<br />Please try again.</h3>
    <p>Please contact <a href="mailto:support+colorbook@thekyoko.com" class="support-email">support@thekyoko.com</a> for assistance.</p>
    <p>Reference Order #{order_ref}</p>

  </Col>
);

const PaymentView = () => {
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const { search } = useLocation(); // Gets the query string part of the URL
  const query = new URLSearchParams(search);
  const session_id = query.get('session_id');
  // const { session_id } = useParams(); // Get the session_id from the URL
  const [paymentStatusSuccess, setPaymentStatusSuccess] = useState(null); // State to track payment status
  const [loading, setLoading] = useState(true); // State to track loading
  const [error, setError] = useState(null);
  const navigateToStudio = () => (window.location.href = '/studio');


  useEffect(() => {
    if (!session_id) {
      setError("Invalid session. No session ID provided.");
      setLoading(false);
      return;
    }

    const checkPaymentStatus = async () => {
      try {
        // Make a request to the backend with the session_id
        const payment_status = await checkPurchaseSuccess(authToken, session_id);
        if (payment_status["status"] === "success") {
          setPaymentStatusSuccess(true);
          dispatch(onGetProfile());
        } else {
          setPaymentStatusSuccess(false);
        }
      } catch (error) {
        setPaymentStatusSuccess(false); // Handle any errors by showing failure
      } finally {
        setLoading(false); // Stop loading once the request is complete
      }
    };

    checkPaymentStatus();
  }, [dispatch, session_id, authToken]);

  if (loading) {
    return <div>Loading...</div>;
  }


  if (error) return <div>{error}</div>;


  return (
    <div>
      {paymentStatusSuccess ? <PaymentSuccess navigateToStudio={navigateToStudio} /> : <PaymentFailure order_ref={session_id} />}
    </div>
  );
};

export default PaymentView;
