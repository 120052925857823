import React, { useState, useEffect } from 'react';

import privacyPolicy from "../assets/legal/privacyPolicy.txt"
import termsAndConditions from "../assets/legal/termsAndConditions.txt"

const LegalDocumentDisplay = ({ document }) => {
  let documentFile = null;
  if (document === "privacy") {
    documentFile = privacyPolicy;
  } else if (document === "terms") {
    documentFile = termsAndConditions;
  }
  else {
    throw new Error("Invalid selection");
  }

  const [text, setText] = useState('');

  useEffect(() => {
    const fetchText = async () => {
      try {
        const response = await fetch(documentFile);
        const data = await response.text();
        setText(data);
      } catch (error) {
        console.error('Error fetching the text file:', error);
      }
    };

    fetchText();
  }, [documentFile]);

  // Split the text by line breaks
  const paragraphs = text.split(/\n{2,}/);

  return (
    <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'Arial, sans-serif', backgroundColor: "white", padding: "10px" }}>
      {/* {lines.map((line, index) => (
        <div key={index}>{line}</div>
      ))} */}
      {paragraphs.map((paragraph, index) => (
        <p key={index} style={{ marginBottom: '1em' }}>
          {paragraph.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      ))}
    </div>
  );
};

export default LegalDocumentDisplay;