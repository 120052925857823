import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStudioCreations } from "../../api";

export const onGetStudioCreations = createAsyncThunk(
  "studio/onGetStudioCreations",
  async (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const studioCreations = await getStudioCreations(token);
    return studioCreations;
  }
);


const studioSlice = createSlice({
  name: "studio",
  initialState: {
    creations: [],
    isFetchingCreations: false,
  },
  reducers: {},
  extraReducers: (builder) => {


    builder.addCase(
      onGetStudioCreations.pending,
      (state) => {
        state.isFetchingCreations = true;
      }
    );
    builder.addCase(onGetStudioCreations.fulfilled, (state, { payload }) => {
      state.creations = payload;
      state.isFetchingCreations = false;
    });
  },
});

export default studioSlice.reducer;
