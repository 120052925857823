import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { NavLink as RouterNavLink, useHistory, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { logoutBackend } from "../../api";
import { selectProfile } from "../../redux/profile/selectors";

import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";
import { selectAuthUser, selectIsAuthenticated } from "../../redux/auth/selectors";
import SignInModal from "../SignInModal";


const NavBar2 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const toggleSignInModal = () => setIsSignInModalOpen(!isSignInModalOpen);

  const history = useHistory();
  const location = useLocation();
  const navigateToProfile = useCallback(() => { closeNavbar(); return history.push('/profile') }, [history]);
  const navigateToPricing = useCallback(() => { closeNavbar(); return history.push('/pricing') }, [history]);

  const profile = useSelector(selectProfile);
  const user = useSelector(selectAuthUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const handleSignInClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleSignInModal();
  }


  const handleSignOut = () => {
    logoutBackend()
      .then(() => signOut(auth))
      .then(() => {
        console.log("User signed out successfully")
        window.location = window.location.origin;
      }).catch(() => console.log("User failed to sign out"))
  };

  const toggle = () => setIsOpen(!isOpen);

  const closeNavbar = () => setIsOpen(false);

  return (
    <div id="header-wrapper">

      <header id="header" className="container">

        <Navbar color="light" light expand="md" id="nav">
          <NavbarBrand tag={Link} to="/">
            <div id="logo">
              <h1>Color</h1>
              <span>by Kyoko</span>
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className={location.pathname === "/" ? "current" : undefined}>
                <RouterNavLink to="/" onClick={closeNavbar}>Welcome</RouterNavLink>
              </NavItem>
              <NavItem className={location.pathname.startsWith("/download") ? "current" : undefined}>
                <RouterNavLink to="/download" onClick={closeNavbar}>Download</RouterNavLink>
              </NavItem>
              <NavItem className={location.pathname.startsWith("/studio") ? "current" : undefined}>
                <RouterNavLink to="/studio" onClick={closeNavbar}>Studio</RouterNavLink>
              </NavItem>
              {!isAuthenticated && (
                <NavItem>
                  <RouterNavLink to="/" onClick={handleSignInClick}>Join / Sign In</RouterNavLink>
                </NavItem>
              )}
              {
                isAuthenticated && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret id="profileDropDown">
                      <img
                        src={user.photoURL}
                        alt="Profile"
                        className="nav-user-profile rounded-circle"
                        width="50"
                      />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem header>{user.email}<br></br>{profile && (<sub>Tokens: {profile.tokens.tokens} Creations: {profile.tokens.used_tokens}</sub>)}</DropdownItem>
                      <DropdownItem onClick={navigateToProfile}>Profile</DropdownItem>
                      <DropdownItem onClick={navigateToPricing}>Buy Tokens</DropdownItem>
                      <DropdownItem id="qsLogoutBtn" onClick={handleSignOut}>
                        <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                        out
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              }
            </Nav>
          </Collapse>
        </Navbar>
        <SignInModal isOpen={isSignInModalOpen} toggle={toggleSignInModal} message="Sign in or create an account with one of our login providers." />
      </header>
    </div >
  );
};

export default NavBar2;
