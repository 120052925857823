import React, { useEffect } from "react";

import { CardGroup, Spinner } from "reactstrap";
import PricingCard from "./PricingCard";
import { onGetPurchaseOptions } from "../redux/profile/slice";
import { selectIsFetchingPurchaseOptions, selectPurchaseOptions } from "../redux/profile/selectors";
import { useDispatch, useSelector } from "react-redux";



const Pricing = () => {
  const dispatch = useDispatch();

  const purchaseOptions = useSelector(selectPurchaseOptions);
  const isFetchingPurchaseOptions = useSelector(selectIsFetchingPurchaseOptions);

  useEffect(() => {
    dispatch(onGetPurchaseOptions())
  }, [dispatch])

  return (
    <div className="text-center">
      <p className="lead">
        Turn your child's imagination into a one-of-a-kind adventure!<br></br>Each Story Token lets you create a magical, 6-page coloring storybook that your little one will cherish.<br></br>Start creating memories today!
      </p>

      {isFetchingPurchaseOptions && <Spinner />}
      {!isFetchingPurchaseOptions &&
        <CardGroup>
          {purchaseOptions.map((p) => <PricingCard key={p.product_id} {...p} />)}
        </CardGroup>
      }

      <p className="important-info">
        By purchasing tokens on Color by Kyoko, you agree to be bound by our <a href="/legal/terms" target="_blank">Terms and Conditions</a>&nbsp;
        and <a href="/legal/privacy" target="_blank">Privacy Policy</a>. Please take a moment to review these important documents to understand your rights and responsibilities.
        If you have any questions or concerns, feel free to contact us at <a href="mailto:support@thekyoko.com">support@thekyoko.com</a>.
      </p>

    </div>
  );
};

export default Pricing;
