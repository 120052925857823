import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { selectProfile, selectGallery } from "../redux/profile/selectors";
import { onGetCreationGallery } from "../redux/profile/slice";
import { selectAuthUser } from "../redux/auth/selectors";
import Loading from "../components/Loading";

export const ProfileComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);

  const profile = useSelector(selectProfile);
  const gallery = useSelector(selectGallery);


  useEffect(() => {
    if (!gallery.inited) {
      dispatch(onGetCreationGallery(1))
    }
  }, [dispatch, gallery.inited]);

  if (!user) {
    return <Row className="align-items-center  mb-5 text-center ">
      <Loading />
    </Row>
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.photoURL}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">Available Tokens: {profile.tokens.tokens}<br></br>Creations: {profile.tokens.used_tokens}</p>
          <p className="lead text-muted">Date Joined: {profile.date_joined}</p>
        </Col>
      </Row>
      {/* <Row>
        <h3>Your Creations</h3>
        <CreationGallery gallery={gallery} handlePageClick={handlePageClick}></CreationGallery>
      </Row> */}
    </Container>
  );
};

export default ProfileComponent;
