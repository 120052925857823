import React, { useState } from "react";

import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Button, Spinner } from "reactstrap";
import { selectAuthToken } from "../redux/auth/selectors";
import { useSelector } from "react-redux";
import { startPurchaseFlow } from "../api";


const PricingCard = ({ product_id, name, description, price, currency, img }) => {

  const [isLoading, setIsLoading] = useState(false);

  const authToken = useSelector(selectAuthToken);

  const onBuyNowClick = async () => {
    setIsLoading(true);
    try {
      const checkout_redirect_url = await startPurchaseFlow(authToken, product_id);
      window.location.href = checkout_redirect_url.url;

    } catch (error) {
      console.error('Error during purchase flow:', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (

    <Card>
      <CardImg
        alt="Card image cap"
        src={img}
        top
        width="100%"
      />
      <CardBody>
        <CardTitle tag="h5">
          {name}
        </CardTitle>
        <CardSubtitle
          className="mb-2 text-muted"
          tag="h6"
        >
          {description}
        </CardSubtitle>
        <CardText>
          {currency === "cad" || currency === "usd" ? `$${price}` : `${price} ${currency.toUpperCase()}`}
        </CardText>
        <Button className="button" onClick={() => onBuyNowClick()} disabled={isLoading}>
          {isLoading ? <Spinner size="sm" /> : "Buy Now"}
        </Button>
      </CardBody>
    </Card>
  );
};


export default PricingCard;
