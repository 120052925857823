import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { downloadCreation } from '../api';

const DownloadButton = ({ label, code, showError }) => {

    const [isLoading, setIsLoading] = useState(false);

    async function blobToJson(blob) {
        // Create a new FileReader instance
        const reader = new FileReader();

        // Return a promise that resolves when the file reading is complete
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                try {
                    // Parse the result as JSON
                    const jsonObject = JSON.parse(reader.result);
                    resolve(jsonObject);
                } catch (err) {
                    reject('Error parsing JSON: ' + err);
                }
            };
            reader.onerror = reject;

            // Read the Blob as text
            reader.readAsText(blob);
        });
    }

    const handleDownload = async () => {
        setIsLoading(true);
        if (code.trim() === "") {
            showError("Must enter a code.");
            setIsLoading(false);
            return;
        }

        try {
            const response = await downloadCreation(code);

            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${code}.pdf`); // You can customize the filename here
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Failed to download PDF');
            }
        } catch (error) {
            console.error('Error fetching the PDF:', error);
            const blob = new Blob([error.response.data]);
            // Turn the blob into a JS object if it contains JSON
            const errorResponse = await blobToJson(blob);
            console.log('Download error:', errorResponse);
            showError(errorResponse.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button className="button" color="primary" onClick={handleDownload} disabled={isLoading}>
            {isLoading ? <Spinner size="sm" /> : label}
        </Button>
    );
};

export default DownloadButton;
