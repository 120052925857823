import React, { useState } from 'react';
import { Collapse, Card, CardBody, CardHeader, Button } from 'reactstrap';

import "./FAQSection.scss"

const FAQSection = ({ faqs }) => {
    // State to keep track of which FAQ is expanded
    const [openIndex, setOpenIndex] = useState(null);

    // Toggle function for collapsible FAQ
    const toggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-section">
            {faqs.map((faq, index) => (
                <Card key={index} className="mb-2">
                    <CardHeader>
                        <Button
                            className='faq-expand-btn'
                            style={{ "text-decoration": "none", "color": "inherit" }}
                            color="link"
                            onClick={() => toggle(index)}
                            aria-expanded={openIndex === index}
                            aria-controls={`collapse-${index}`}
                        >
                            {faq.question}
                        </Button>
                    </CardHeader>
                    <Collapse isOpen={openIndex === index}>
                        <CardBody>
                            {faq.answer}
                        </CardBody>
                    </Collapse>
                </Card>
            ))}
        </div>
    );
};

export default FAQSection;
