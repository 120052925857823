import { useHistory } from "react-router-dom";

const NavAnchor = ({ path, children, className }) => {

    const history = useHistory();
    const navigateToPage = (path) => {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();

            history.push(path);
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'auto' });
            }, 100)

        }
    }

    return (
        // eslint-disable-next-line
        <a href="#" onClick={navigateToPage(path)} className={className}>{children}</a>
    )
}

export default NavAnchor;