import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import NavAnchor from './NavAnchor';


const ImageCarousel = ({ images, itemsPerSlide = 3 }) => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const groupedImages = [];
    for (let i = 0; i < images.length; i += itemsPerSlide) {
        groupedImages.push(images.slice(i, i + itemsPerSlide));
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === groupedImages.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const prevIndex = activeIndex === 0 ? groupedImages.length - 1 : activeIndex - 1;
        setActiveIndex(prevIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = groupedImages.map((group, index) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
                slide={false}
            >
                <Row className="d-flex">
                    {group.map((item) => (
                        <Col key={item.src} className="px-1" style={{ flex: 1 }}>
                            <NavAnchor path={item.link}>
                                <img src={item.src} alt={item.altText} className="d-block w-100" />
                            </NavAnchor>
                        </Col>
                    ))}
                </Row>
            </CarouselItem>
        );
    });

    return (
        <Carousel activeIndex={activeIndex} next={next} previous={previous} slide={false} ride='carousel' interval={3000}>
            <CarouselIndicators items={groupedImages.map((v, index) => ({ "src": v, "key": index }))} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} className="carousel-control-color" />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} className="carousel-control-color" />
        </Carousel>
    );
};

// Function to preload images
const preloadImages = (imageUrls) => {
    return new Promise((resolve) => {
        const loadedImages = [];
        imageUrls.forEach((url) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                loadedImages.push(url);
                if (loadedImages.length === imageUrls.length) {
                    resolve();
                }
            };
        });
    });
};

const InstagramGallery = ({ profileImage, instagramHandle, images }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const imageUrls = images.map((value) => value.src);

        preloadImages(imageUrls).then(() => {
            setIsLoading(false); // Stop loading once images are preloaded
        });
    }, [images]);

    return (
        <Container style={{
            backgroundColor: "white",
            borderRadius: "10px"
        }}>
            <Row className="my-4">
                <Col md="3" xs="3">
                    <img src={profileImage} alt="Profile" className="rounded-circle img-fluid" />
                </Col>
                <Col md="9" xs="9" className='justify-content-left align-items-left' style={{ textAlign: "left", margin: "auto" }}>
                    <h3 className='ig-handle-no-highlight'>
                        <a href={`https://instagram.com/${instagramHandle}`} target="_blank" rel="noopener noreferrer">
                            @{instagramHandle}
                        </a>
                    </h3>
                    <p style={{ marginBottom: "1em" }}>Free Coloring Story Books for Kids | Make your own</p>
                    <Button color="primary" href={`https://instagram.com/${instagramHandle}`} target="_blank" rel="noopener noreferrer" className='button' style={{
                        backgroundColor: '#E1306C',
                        borderColor: '#E1306C',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: "fit-content",
                        fontSize: "0.8em",
                    }}>
                        <i className="icon brands fa-instagram" style={{ marginRight: '8px' }}></i>
                        View Gallery
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {isLoading ? <Spinner /> :
                        <ImageCarousel images={images} />
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default InstagramGallery;
