import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import NavAnchor from '../NavAnchor';

const ConfirmationModal = ({ isOpen, toggle, onConfirm }) => {

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirm Action</ModalHeader>
            <ModalBody>
                You are about to use a token to perform this action. Do you want to proceed? Tokens are non-refundable once used. By proceeding, you agree to our <NavAnchor path="/legal/terms">Terms and Conditions</NavAnchor> and <NavAnchor path="/legal/privacy">Privacy Policy</NavAnchor>.
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>Agree</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmationModal;
