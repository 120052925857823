import React from 'react';
import FAQSection from '../components/FAQSection'; // Adjust the path as needed

const faqs = [
    { question: 'What is Color by Kyoko?', answer: 'Color by Kyoko is a platform for sharing and creating custom coloring book stories.' },
    { question: 'Do I need to purchase anything to download free coloring books?', answer: 'Nope! All the coloring books shared on our instagram are free to download! Just print at home and enjoy!' },
    { question: 'What are tokens for?', answer: 'Tokens allow users to create their own stories and coloring creations.' },
    { question: 'How do I purchase tokens?', answer: "You can purchase tokens through our website. You'll need to create an account first, though!" },
    { question: 'Is the content AI-generated?', answer: 'Yes, our platform uses AI to assist in content creation, but we monitor to prevent inappropriate results. Please familiarize yourself with our Terms and Conditions and Privacy pages for more information.' },
    { question: 'What is Kyoko?', answer: 'Kyoko (or Kyoko Aritficial Intelligence Technologies Corp) is a Canadian company focused on building AI products and tools. To learn more please head to thekyoko.com' },

];

const FAQs = () => (
    <div>
        <h1>Frequently Asked Questions</h1>
        <FAQSection faqs={faqs} />
    </div>
);

export default FAQs;