import Axios from "axios";

function createReqConfig(token) {
  const config = {
    headers: {
      "Content-Type": "application/json"
    },
  };
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
}

// ------ Logout -----
export const logoutBackend = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    },
  };
  const response = await Axios.get("/api/logout", config);
  return response.data;
}


// ------ Profile -------
export const getProfile = async (token) => {
  const config = createReqConfig(token);
  const response = await Axios.get("/api/profile", config);
  return response.data;
}

export const updateProfileHandle = async (token, handle) => {
  const config = createReqConfig(token);
  const response = await Axios.post("/api/profile", { handle }, config);
  return response.data;
}


// ------ Content -------

export const downloadCreation = async (code) => {
  const response = await Axios.get(`/api/library/${code}`, {
    responseType: 'blob', // Important for handling binary data
    headers: {
      'Content-Type': 'application/pdf',
    },
  });
  return response;
};


export const getCreationGallery = async (token, page) => {
  const config = createReqConfig(token);
  config["params"] = {
    page
  };
  const response = await Axios.get(`/api/library/mygallery`, config);
  return response.data;
};

// -------- Payments --------

export const getPurchaseOptions = async () => {
  const config = createReqConfig(null);
  const response = await Axios.get(`/api/payment`, config);
  return response.data;
};

export const startPurchaseFlow = async (token, product_id) => {
  const config = createReqConfig(token);
  config["params"] = {
    product_id
  }
  const response = await Axios.get(`/api/payment/start`, config);
  return response.data;
}

export const checkPurchaseSuccess = async (token, session_id) => {
  const config = createReqConfig(token);
  config["params"] = {
    session_id
  }
  const response = await Axios.get(`/api/payment/check`, config);
  return response.data;
}

// ------ Studio ------

export const getStudioCreations = async (token) => {
  const config = createReqConfig(token);
  const response = await Axios.get(`/api/studio/creations`, config);
  return response.data;
};

export const startStoryCreation = async (token) => {
  const config = createReqConfig(token);
  const response = await Axios.get(`/api/studio/start`, config);
  return response.data;
}


export const generateStory = async (token, reference_code, description) => {
  const config = createReqConfig(token);
  const body = {
    reference_code,
    description
  }

  const response = await Axios.post(`/api/studio/generate`, body, config);
  return response.data;
}


export const checkStoryProgress = async (token, reference_code) => {
  const config = createReqConfig(token);
  config["params"] = {
    reference_code
  }
  const response = await Axios.get(`/api/studio/progress`, config);
  return response.data;
}
