// src/components/InAppBrowserWarning.js

import React, { useEffect, useState } from 'react';
import { Alert, Container } from 'reactstrap';
import { isInAppBrowser, getOS } from '../utils/browser';

const InAppBrowserWarning = () => {
    const [isInApp, setIsInApp] = useState(false);

    const isInEmbeddededBrowser = isInAppBrowser();
    const clientOS = getOS();

    useEffect(() => {
        if (isInAppBrowser()) {
            setIsInApp(true);
        }
    }, []);

    if (!isInApp) return null; // Only show the message if in an in-app browser

    return (
        <Container className="my-4">
            <Alert color="warning" className="text-center">
                <h5>Embedded Browser Not Supported</h5>
                <p>
                    It looks like you're using Instagram or another app's browser. Currently, embedded browsers cause technical issues for our website. Please open this page in your default mobile browser (Safari, Chrome, etc.).
                    <br />
                    <strong>If using Instagram,</strong> please tap the three dots (•••) in the top right corner and choose 'Open in external browser' to continue.
                </p>
                {isInEmbeddededBrowser && clientOS === "Android" &&
                    <a href='https://kyoko.ink' download className='button'>Open in Browser</a>}
                {isInEmbeddededBrowser && clientOS === "iOS" &&
                    <a href={`shortcuts://x-callback-url/run-shortcut?name=${crypto.randomUUID()}&x-error=${encodeURIComponent('https://kyoko.ink')}`} className='button'>Open in Browser</a>}
            </Alert>

        </Container>
    );
};

export default InAppBrowserWarning;
