import React from 'react';
import { CardGroup } from 'reactstrap';
import CreationCard from './CreationCard';

const StudioGallery = ({ creations, onCreateNew, onEditCreation }) => {
    // Split the creations into groups
    const cardsPerGroup = 4;
    let creationGroups = [];

    const sortedCreations = [...creations].sort((a, b) => {
        if (a["started_at"] < b["started_at"]) return 1;
        if (a["started_at"] > b["started_at"]) return -1;
        return 0;
    });
    if (creations.length > 0) {
        creationGroups = sortedCreations.reduce((groups, creation, index) => {
            const groupIndex = Math.floor((index + 1.0) / cardsPerGroup);
            if (!groups[groupIndex]) {
                groups[groupIndex] = [];
            }
            groups[groupIndex].push(creation);
            return groups;
        }, [["Add"]]);

    } else {
        creationGroups = [["Add"]]
    }

    return (
        <>
            {creationGroups.map((group, groupIndex) => (
                <CardGroup key={groupIndex} className='custom-card-group'>
                    {group.map((creation, index) => {
                        if (creation === "Add") {
                            return (<CreationCard key={index} isNew onClick={onCreateNew} />)
                        }

                        return (<CreationCard
                            key={index}
                            code={creation.reference_code}
                            title={creation.title}
                            status={creation.status}
                            started_at={creation.started_at}
                            thumbnail={creation.thumbnail}
                            timesDownloaded={creation.times_downloaded}
                            onEdit={() => onEditCreation(creation.reference_code)}
                        />)
                    })
                    }
                </CardGroup>
            ))
            }
        </>
    );
};

export default StudioGallery;
