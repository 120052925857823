import React, { useEffect } from "react";
import { Router, Route, Switch, useLocation } from "react-router-dom";
import { Container } from "reactstrap";

import NavBar2 from "./components/NavBar2";
import Footer from "./components/Footer";
import Home from "./views/Home";
import DownloadView from "./views/Download";
import Profile from "./views/Profile";
import StoryCreator from "./views/StoryCreator";
import history from "./utils/history";
import { onFirebaseLoadingCompleted } from "./redux/auth/slice";

import initFontAwesome from "./utils/initFontAwesome";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { selectAuthToken } from "./redux/auth/selectors";
import { onGetProfile } from "./redux/profile/slice";
import PricingView from "./views/Pricing";
import PaymentView from "./views/Payment";
import Studio from "./views/Studio";
import TermsAndConditionsView from "./views/TermAndConditions";
import PrivacyPolicyView from "./views/PrivacyPolicy";
import FAQs from "./views/FAQ";
import HowItWorks from "./views/HowItWorks";
import InAppBrowserWarning from "./components/InAppBrowserWarning";
import CleanupURL from "./components/CleanupURL";
import { analytics } from "./config/firebase";
import { logEvent } from "firebase/analytics";


initFontAwesome();

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to the top of the page
  }, [pathname]); // Depend on pathname to trigger effect on route change

  return null;
};


// Component that logs page views
const AnalyticsLogger = () => {
  const location = useLocation();

  useEffect(() => {
    // logEvent(analytics, 'page_view');
    const currentPath = location.pathname + location.search; // Add search params if needed
    const pageTitle = document.title || 'Untitled Page'; // You can customize this
    logEvent(analytics, 'page_view', {
      page_title: pageTitle, // Set custom title
      page_location: window.location.href, // Full URL
      page_path: currentPath // Path to track
    });

  }, [location]);

  return null; // This component doesn't render anything
};

const App = () => {
  const dispatch = useDispatch();
  const auth = getAuth();
  const authtoken = useSelector(selectAuthToken);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      dispatch(onFirebaseLoadingCompleted({ user }))
        .catch((error) => console.error("Thunk error:", error));
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [dispatch, auth]);

  useEffect(() => {
    if (authtoken) {
      dispatch(onGetProfile());
    }
  }, [dispatch, authtoken]);

  return (
    <Router history={history}>
      <div id="app homepage">
        <AnalyticsLogger />
        <InAppBrowserWarning />
        <CleanupURL />
        <ScrollToTop />
        <NavBar2 />
        <Container >
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/download/:code?" component={DownloadView} />
            <Route path="/studio/story/:code?" component={StoryCreator} />
            <Route path="/studio" component={Studio} />
            <Route path="/pricing" component={PricingView} />
            <Route path="/profile" component={Profile} />
            <Route path="/payment" component={PaymentView} />
            <Route path="/legal/terms" component={TermsAndConditionsView} />
            <Route path="/legal/privacy" component={PrivacyPolicyView} />
            <Route path="/how-it-works" component={HowItWorks} />
            <Route path="/faq" component={FAQs} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};


export default App;
