import React, { Fragment } from "react";

// import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
// import Content from "../components/Content";

const PricingView = () => (
  <Fragment>
    <Pricing />
    {/* <Hero /> */}

    {/* <hr /> */}
    {/* <Content /> */}
  </Fragment>
);

export default PricingView;
