export function isInAppBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detect Instagram, Facebook, or other known in-app browsers
    if (userAgent.includes('Instagram') || userAgent.includes('FBAN') || userAgent.includes('FBAV')) {
        return true;
    }

    return false;
}

export const getOS = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    } else {
        return 'Other';
    }
};

