import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';

import NavAnchor from '../components/NavAnchor';

import GalleryImage from "../assets/images/gallery.jpg";
import DownloadImage from "../assets/images/downloadview.jpg";
import StudioImage from "../assets/images/studio.jpg";
import TokenImage from "../assets/images/5Tokens.jpg";
import StudioMakeImage from "../assets/images/studiomake.jpg";

import "./HowItWorks.scss"

const HowItWorks = () => {

    const subCardstyle = {
        "marginRight": "10px",
        "marginTop": "0px"
    }
    return (
        <Container className="how-it-works">
            <h2 className="text-center mb-4 pb-4">How It Works</h2>

            <Row >
                {/* <Col md="6"> */}
                <Card>
                    <CardBody>
                        <CardTitle tag="h5">🖍️ Free Coloring Book Downloads</CardTitle>
                        <CardText>
                            Ready for some fun? 🎨 Dive into our Instagram account to find secret codes hidden in our colorful posts. Just type in these magical codes at <strong>kyoko.ink/download/{`{code}`}</strong> and hit <strong>Download</strong>! 🖨️ Print out your free coloring book and let the creativity flow for your little ones!
                        </CardText>

                        <CardImg src={GalleryImage} />

                        <CardImg src={DownloadImage} />
                    </CardBody>
                </Card>
                {/* </Col> */}
                {/* <Col> */}
                {/* <Row> */}
                {/* <Card>

                    <CardBody>

                        <CardImg src={DownloadImage} />
                    </CardBody>
                </Card> */}
                {/* </Row> */}
                {/* </Col> */}
            </Row>
            <hr></hr>

            <Row>
                {/* <Col>
                    <img src={StudioImage} style={imageStyle}></img></Col> */}

                <Card>
                    <CardBody>
                        <CardTitle tag="h5">🏠 Studio</CardTitle>
                        <CardText>
                            Our Studio is where the magic happens! ✨ But before you start, here's a quick tour of the two cool features:
                        </CardText>
                        <CardImg src={StudioImage}></CardImg>
                    </CardBody>
                </Card>
            </Row>

            <Row style={subCardstyle}>
                <Col md="6">
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">💰 Tokens</CardTitle>
                            <CardText>
                                Want to create your own masterpieces? You'll need tokens! 🎟️ Just sign up with your email (it's super easy!) and buy some tokens. Each creation in the Studio will use one of these tokens. Start creating by just a few clicks!
                            </CardText>

                            <CardImg src={TokenImage}></CardImg>
                        </CardBody>
                    </Card>
                </Col>

                <Col md="6">
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">🎨 Studio Usage</CardTitle>
                            <CardImg src={StudioMakeImage} />
                            <CardText>
                                Once you're all set with tokens, hop into the Studio. Here's how you create a magical book for your kids:
                                <ul>
                                    <li>Create an account and sign in.</li>
                                    <li>Go to the Studio and hit <strong>Create</strong>.</li>
                                    <li>Enter a fun description and hit <strong>Generate</strong>.</li>
                                    <li>Once your book is ready, just press <strong>Download</strong>!</li>
                                </ul>
                                Voila! Your very own custom coloring book is ready to be printed and enjoyed! 🌟
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <hr></hr>

            <Row >

                <Card>
                    <CardBody>
                        <CardTitle tag="h5">🤖 AI Use in Our Product</CardTitle>
                        <CardText>
                            Our platform harnesses the power of AI to help you create unique and exciting coloring books! 🎉 While we strive to provide high-quality results, it's important to remember that AI may sometimes produce unexpected outcomes. 🧠
                            <br /><br />
                            We are committed to ensuring the safety and appropriateness of our content, but it's always a good idea to stay informed about how AI works and its potential risks. Please review our <NavAnchor path="/legal/terms">Terms and Conditions</NavAnchor> and <NavAnchor path="/legal/privacy-policy">Privacy Policy</NavAnchor> to understand how we manage and protect your data.
                            <br /><br />
                            Your trust is important to us, and we want you to have the best experience possible. If you have any concerns or questions, feel free to reach out to our support team!
                        </CardText>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
};

export default HowItWorks;
