import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const PurchaseTokensModal = ({ isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Purchase Tokens</ModalHeader>
            <ModalBody>
                You need more tokens to continue. Please purchase additional tokens.
            </ModalBody>
            <ModalFooter>
                <Button color="primary" tag={Link} to="/pricing">Go to Pricing</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default PurchaseTokensModal;
