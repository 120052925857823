import React from "react";
import { Row } from "reactstrap";

import instagramProfileImg from "../assets/images/instagramProfile.jpg"
import InstagramGallery from "../components/InstagramGallery";

const Gallery = () => {

  const profileImage = instagramProfileImg;
  const instagramHandle = 'coloring.story.books';
  const favorites = ["HF4Z", "VSDA", "DZBI", "MIBQ", "5P43", "KWAA", "2054", "7AWH", "ZX9U"]
  const images = favorites.map((createCode, index) => {
    return {
      src: `/api/library/preview/${createCode}`,
      altText: `Book ${index}`,
      link: `/download/${createCode}`
    };
  });

  return (
    <div className="text-center my-5">

      <Row id="free-downloads">
        <div style={{ marginBottom: "10px", textAlign: "center" }}>
          <h2>Browse our free gallery on Instagram</h2>
          <h3>Look for the download code in the post and enter it in the downloads page</h3>
        </div>
      </Row>
      <InstagramGallery instagramHandle={instagramHandle} profileImage={profileImage} images={images} />
    </div>
  );
};

export default Gallery;
