import React, { useState } from 'react';
import { Card, CardImg, CardBody, CardText, Col } from 'reactstrap';
import LoadingImage from "../../assets/images/LoadingAlternative.gif"
import ErrorImage from "../../assets/images/error1.jpeg"
// import { FaEdit } from 'react-icons/fa';
import "./studio.scss"

const CreationCard = ({
    code,
    title,
    status,
    started_at,
    thumbnail,
    timesDownloaded,
    onEdit,
    isNew,
    onClick
}) => {
    const [isHovered, setIsHovered] = useState(false);

    let displayImage = null;
    let displayStatus = null;

    if (thumbnail) {
        displayImage = thumbnail;
    } else if (status === "INPROGRESS" || status === "PENDING") {
        displayImage = LoadingImage;
    } else {
        displayImage = ErrorImage;
    }

    if (status === "INPROGRESS" || status === "PENDING") {
        displayStatus = "In progress...";
    } else if (status === "FINISHED") {
        displayStatus = "Finished"
    } else {
        displayStatus = "Error"
    }


    return (
        <Card
            className="creation-item hover-card custom-card"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={isNew ? onClick : undefined}
            style={{ cursor: isNew ? 'pointer' : 'default' }}
        >
            {isNew ? (
                <CardBody className="d-flex justify-content-center align-items-center">
                    <Col style={{ textAlign: "center" }}>
                        <i className="fa fa-plus fa-2x" aria-hidden="true"></i>
                        <p>Create</p>
                    </Col>
                </CardBody>
            ) : (
                <>
                    <CardImg top src={displayImage} alt="Creation thumbnail" />
                    <CardBody>
                        <CardText><strong>{code.toUpperCase()}</strong></CardText>
                        <CardText>{displayStatus}</CardText>
                        <div className={`overlay ${isHovered ? 'visible' : ''}`}>
                            <i className="fa fa-edit fa-2x" aria-hidden="true" onClick={onEdit}></i>
                        </div>
                    </CardBody>
                </>
            )}
        </Card>
    );
};

export default CreationCard;
