import React, { Fragment } from "react";
import Hero from "../components/Hero";
import Gallery from "../components/Gallery";
import { Row, Col } from "reactstrap";

import NavAnchor from "../components/NavAnchor";

const AboutSection = () => {

  return (
    <div id="main-wrapper">
      <div className="container">
        <Row>
          <Col id="about">
            <div id="content imp-medium">
              <section className="last">
                <h2>So what's this all about?</h2>
                <section id="whats-this-all-about" className="fun-intro">
                  <p style={{ fontSize: "xx-large", lineHeight: "normal" }}>Welcome to a colorful corner of the internet where kids can find free and fun coloring books! 🌈</p>

                  <p style={{ fontSize: "large" }}>We're all about building a community of everyday creators who love to share their artwork. Check out our Instagram to see the best of the best—maybe your masterpiece will be next!</p>

                  <p style={{ fontSize: "large" }}>Want to create your own story? Just create an account, grab some story tokens, and let our Studio bring your ideas to life!</p>

                  <p style={{ fontSize: "large" }}>Let's get coloring and creating together!</p>

                </section>
                <NavAnchor href="#" path="/how-it-works" className="button icon solid fa-arrow-circle-right">Continue Reading</NavAnchor>
              </section>
            </div>

          </Col>
        </Row>
      </div>
    </div>
  )

};

const Home = () => {

  return (
    <Fragment>
      <Hero />
      <Gallery />
      <AboutSection></AboutSection>
    </Fragment>
  );
}

export default Home;
