import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

let firebaseConfig;

if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
    firebaseConfig = {
        apiKey: "AIzaSyDaBb8G-OjybQpRvxxkGzKwCRbhJDGhzUo",
        authDomain: "kyoko-ink-dev.firebaseapp.com",
        projectId: "kyoko-ink-dev",
        storageBucket: "kyoko-ink-dev.appspot.com",
        messagingSenderId: "935060626873",
        appId: "1:935060626873:web:69bf46343339534a166a68",
        measurementId: "G-K1NLC8HG9Y"
    };
} else if (process.env.REACT_APP_ENVIRONMENT === "QA") {
    firebaseConfig = {
        apiKey: "AIzaSyCbGvcLRlkOCWHdR62Lor1svdHoYnLCrI0",
        authDomain: "kyoko-ink-qa.firebaseapp.com",
        projectId: "kyoko-ink-qa",
        storageBucket: "kyoko-ink-qa.appspot.com",
        messagingSenderId: "851873013658",
        appId: "1:851873013658:web:f6d15a50f670c61e7ba423",
        measurementId: "G-ZCH63XVQQ5"
    };
}
else if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
    firebaseConfig = {
        apiKey: "AIzaSyDsM5J_EtTEoVoIx9_EhvtWZvZCldWfsxQ",
        authDomain: "kyoko-ink.firebaseapp.com",
        projectId: "kyoko-ink",
        storageBucket: "kyoko-ink.appspot.com",
        messagingSenderId: "373415953636",
        appId: "1:373415953636:web:2289a07d5dd089a44c58f1",
        measurementId: "G-YDJ0EFC258"
    };
} else {
    throw new Error(`REACT_APP_ENVIRONMENT not one of: DEV, QA, PROD, actual: ${process.env.REACT_APP_ENVIRONMENT}`)
}

// Initialize config_to_use
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

// Google provider
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, analytics };
