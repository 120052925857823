import { configureStore } from '@reduxjs/toolkit'
import authReducer from './redux/auth/slice';
import uiReducer from './redux/ui/slice';
import profileReducer from './redux/profile/slice';
import studioReducer from './redux/studio/slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    profile: profileReducer,
    studio: studioReducer,
  },
});
