import { useEffect } from 'react';

const CleanupURL = () => {
    /* This Component removes the shortcut hack parameters 
    
    Explanation: https://github.com/shalanah/inapp-debugger/pull/2
    */
    useEffect(() => {
        // Create a new URL object from the current window location
        const url = new URL(window.location.href);

        // List of parameters you want to remove
        const paramsToRemove = ['errorDomain', 'errorMessage', 'errorCode']; // Add any other unwanted params here

        // Remove unwanted parameters
        paramsToRemove.forEach(param => url.searchParams.delete(param));

        // Update the URL without reloading the page
        window.history.replaceState({}, document.title, url.href);
    }, []);

    return null; // This component doesn't render anything
};

export default CleanupURL;
