import React from 'react';
import LegalDocumentDisplay from "../components/LegalDocumentDisplay";

function TermsAndConditionsView() {
    return (
        <LegalDocumentDisplay document="terms" />
    );
}

export default TermsAndConditionsView;
