import { useState } from 'react';
import { Alert } from 'reactstrap';

export const useErrorHandling = () => {
    const [errorMessage, setErrorMessage] = useState(null);

    const showError = (message) => {
        setErrorMessage(message);
        // Optionally, you can set a timer to auto-clear the error
        setTimeout(() => setErrorMessage(null), 5000);
    };

    const ErrorAlert = () => {
        return errorMessage ? (
            <Alert color="danger" toggle={() => setErrorMessage("")} >
                {errorMessage}
            </Alert>
        ) : null;
    };

    return { showError, ErrorAlert };
};