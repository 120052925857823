import React, { useState, useEffect } from 'react';
import {
    Alert, Container, Row, Col, Button, Input, Progress, FormGroup, Label,
    CardGroup, Card, CardImg, CardBody, CardText, Badge
} from 'reactstrap';

import banner from "../assets/images/BannerImage.webp"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { checkStoryProgress, generateStory } from '../api';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../redux/auth/selectors';
import DownloadButton from '../components/CreationDownloadButton';
import LoadingImage from "../assets/images/LoadingAlternative.gif";

import "./StoryCreator.scss"

const ExampleInput = ({ textValue, setTextValue, disabled, errorMessage, setErrorMessage }) => {
    const examples = [
        "Dogs that are too big",
        "Penguin dance party",
        "Cats hunting for treasure",
        "Unicorns learning to fly",
        "A robot and a pirate become friends"
    ];

    const handleExampleClick = (example) => {
        setTextValue(example);
    };

    return (
        <div>
            <Label for="storyInput">Enter your book idea:</Label>
            <Input
                id="storyInput"
                type="text"
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
                placeholder="Enter your story idea..."
                disabled={disabled}
            />

            {/* Row: Error Alert */}
            {errorMessage && (
                <Row>
                    <Col>
                        <Alert color="danger" toggle={() => setErrorMessage("")}>
                            {errorMessage}
                        </Alert>
                    </Col>
                </Row>
            )}

            {!disabled &&
                <div className="mt-2">
                    <h6>Need inspiration? Try these:</h6>
                    {examples.map((example, index) => (
                        <Badge
                            key={index}
                            color="info"
                            pill
                            className="mr-2 mb-2"
                            style={{ cursor: 'pointer', padding: "5px 10px" }}
                            onClick={() => handleExampleClick(example)}
                        >
                            {example}
                        </Badge>
                    ))}
                </div>
            }
        </div>
    );
};


const PreviewPage = ({ page }) => {
    const { image_url, text } = page;

    const displayImage = image_url || LoadingImage;


    return (
        <Card className='custom-card-creator'>
            <CardImg top src={displayImage} alt="Creation thumbnail" />
            <CardBody>
                <CardText>{text}</CardText>
            </CardBody>
        </Card>
    )

}

const ProgressDisplay = ({ progress }) => {

    const { status, title, story, pages, total_pages } = progress;

    const cardsPerGroup = 3;
    let pageGroups = [];
    if (pages && pages.length > 0) {
        pageGroups = pages.reduce((groups, page, index) => {
            const groupIndex = Math.floor(index / cardsPerGroup);
            if (!groups[groupIndex]) {
                groups[groupIndex] = [];
            }
            groups[groupIndex].push(page);
            return groups;
        }, []);
    }

    if (story && status === "INPROGRESS") {
        // Add the loading card to the card group
        const groupIndex = Math.floor(pages.length / cardsPerGroup);
        if (!pageGroups[groupIndex]) {
            pageGroups[groupIndex] = [];
        }
        if (pages.length < total_pages) {
            pageGroups[groupIndex].push({ image_url: LoadingImage, text: "Furiously Drawing Next Page..." });
        }
    }

    return (
        <Col>
            <Row>
                <div>
                    <h3>Title: {title || "Working on a fun title..."}</h3>
                </div>
            </Row>

            <Row>
                {story && (
                    <div>
                        <h3>Story:</h3>
                        <p>{story}</p>
                    </div>
                )}
                {!story && (
                    <div>
                        <h3>Story:</h3>
                        <p>Writing the story...</p>
                    </div>
                )
                }
            </Row>
            <Row>
                {pageGroups && pageGroups.map((group, groupIndex) => (
                    <CardGroup key={groupIndex} className='custom-card-group-creator'>
                        {group.map((page, index) => (
                            <PreviewPage key={index} page={page}></PreviewPage>
                        ))}
                    </CardGroup>
                ))}

            </Row>
        </Col>

    );

};

const StoryCreator = () => {
    const authToken = useSelector(selectAuthToken);
    const { code } = useParams(); // Get the code from the URL

    const [isEnabled, setIsEnabled] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [progressVisible, setProgressVisible] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [generationDone, setGenerationDone] = useState(false);
    const [generationFailed, setGenerationFailed] = useState(false);
    const [progressContent, setProgressContent] = useState('');

    const [errorMessage, setErrorMessage] = useState("");

    const setStoryState = (response) => {
        setProgressValue(response.percent);
        setProgressContent(response);
    }

    const handleGenerateClick = async () => {
        // Call the backend to start generation
        setIsEnabled(false);
        setErrorMessage("");  // Clear any existing error message

        try {
            await generateStory(authToken, code, inputValue);
            // Set loading spinner or something
            setProgressVisible(true);
        } catch (error) {
            console.error('Error starting generation:', error);

            // Set the error message from the backend response
            const backendMessage = error.response?.data?.message || 'An unexpected error occurred. Please try again.';
            setErrorMessage(backendMessage);

            setIsEnabled(true); // Re-enable the button for retry
        }
    };

    useEffect(() => {
        // Check if code already has a creation in progress
        // If so load the status of it and start polling it
        // Disable creation button
        checkStoryProgress(authToken, code).then(progress => {
            if (progress.status) {
                setStoryState(progress);
                if (progress.status === "FINISHED") {
                    setGenerationDone(true);
                    // setProgressVisible(true);
                } else if (progress.status === "INPROGRESS" || progress.status === "PENDING") {
                    // setProgressVisible(true);
                } else if (progress.status === "FAILED") {
                    setGenerationFailed(true);
                    setGenerationDone(true);
                }
                setProgressVisible(true);
                // Start polling and load
                setInputValue(progress.src_description);
                setIsEnabled(false);
            } else {
                // Havent hit generate yet
                setIsEnabled(true);
                setInputValue("");
            }
        })

        // Else, enable creation button
    }, [authToken, code])


    // const handleCreateSuggestion = async () => {
    //     // Call the backend to start generation
    //     try {
    //         await axios.post('/api/start-generation', { input: inputValue });
    //         setProgressVisible(true);
    //     } catch (error) {
    //         console.error('Error starting generation:', error);
    //     }
    // };

    useEffect(() => {
        let interval;
        if (progressVisible && !generationDone) {
            // Poll the backend for progress updates
            interval = setInterval(async () => {
                try {
                    const response = await checkStoryProgress(authToken, code);
                    setStoryState(response);

                    if (response.percent >= 100) {
                        setGenerationDone(true);
                        clearInterval(interval);
                    }

                    if (response.status === "FAILED") {
                        setGenerationFailed(true);
                        setGenerationDone(true);
                        clearInterval(interval);
                    }
                } catch (error) {
                    console.error('Error fetching progress:', error);
                }
            }, 2000); // Poll every 2 seconds
        }
        return () => clearInterval(interval);
    }, [progressVisible, generationDone, authToken, code]);

    return (
        <Container>
            {/* Row: Header banner image */}
            <Row className="mb-4">
                <Col>
                    <img src={banner} alt="Header Banner" className="img-fluid" />
                </Col>
            </Row>

            {/* Row: Title */}
            <Row className="mb-4 text-center">
                <Col>
                    <h1>COLORING BOOK STUDIO</h1>
                    <h3>Build your own coloring book</h3>
                </Col>
            </Row>

            {/* Row: Input field with Generate button */}
            <Row className="mb-4" style={{ backgroundImage: "url('/path-to-background-image.jpg')", backgroundSize: 'cover' }}>
                <Col>
                    <FormGroup>
                        <ExampleInput textValue={inputValue} setTextValue={setInputValue} disabled={!isEnabled} errorMessage={errorMessage} setErrorMessage={setErrorMessage}></ExampleInput>
                    </FormGroup>
                    <Button className="button" color="primary" disabled={!isEnabled} onClick={handleGenerateClick}>Generate</Button>
                </Col>
            </Row>

            {/* Row: Progress display and bar */}
            {progressVisible && (
                <Row className="mb-4">
                    <Col className='story-writing-container'>
                        <div>
                            {/* <h4>Progress</h4> */}
                            <ProgressDisplay progress={progressContent}></ProgressDisplay>
                            {/* <div>{JSON.stringify(progressContent)}</div> */}
                            <Progress animated={!generationDone} color={generationFailed ? "danger" : "info"} value={!generationFailed ? progressValue : 100}>
                                {generationDone ? (!generationFailed ? <span>&#10003; Done</span> : (<span>&#33; Failed ({progressValue}%)</span>)) : `${progressValue}%`}
                            </Progress>
                        </div>
                    </Col>
                </Row>
            )}

            {/* Row: Options after generation */}
            {generationDone && (
                <Row className="mb-4 text-center">
                    <Col>
                        {generationFailed ?
                            <Alert color="danger">
                                Story book creation failed! We are looking into it on our end, and will try to resolve it soon, so please check back later.
                            </Alert>
                            :
                            <DownloadButton code={code} label={"Download"} />
                        }
                        {/* <Button color="success" className="mr-2">Publish</Button>
                        <Button color="warning">Rewrite</Button> */}
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default StoryCreator;
