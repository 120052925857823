import React from "react";
import NavAnchor from "./NavAnchor";
import { Row, Col } from "reactstrap";

const Footer = () => {

  return (

    <footer id="footer">
      <Row>
        <Col sm="4"
          xs="6">

          <section className="widget links" style={{ height: "100%" }}>
            <h3>How It Works</h3>
            <ul className="style2">
              <li><NavAnchor path="/how-it-works">How it works</NavAnchor></li>
              <li><NavAnchor path="/faq">Frequently Asked Questions</NavAnchor></li>
            </ul>
          </section>

        </Col>
        <Col sm="4"
          xs="6">

          <section className="widget links" style={{ height: "100%" }}>
            <h3>Legal</h3>
            <ul className="style2">
              <li><NavAnchor href="#" path="/legal/terms">Terms and Conditions</NavAnchor></li>
              <li><NavAnchor href="#" path="/legal/privacy">Privacy Policy</NavAnchor></li>
            </ul>
          </section>

        </Col>
        <Col sm="4">
          <section className="widget contact last" style={{ height: "100%" }}>
            <h3>Contact Us</h3>
            <ul>
              {/* <li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li> */}
              {/* <li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li> */}
              <li><a href="mailto:support@thekyoko.com?subject=Color%20Support" className="icon  fa-envelope"><span className="label">Email</span></a></li>
              <li><a href="https://www.instagram.com/coloring.story.books/" target="_blank" rel="noreferrer" className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
              <li><a href="https://pin.it/4wBJzMovk" target="_blank" rel="noreferrer" className="icon brands fa-pinterest"><span className="label">Pinterest</span></a></li>
            </ul>
            <p>support@thekyoko.com<br></br>
              DM us on instagram: <br></br>@coloring.story.books
            </p>
          </section>

        </Col>
      </Row>
      <div className="row">
        <div className="col-12">
          <div id="copyright">
            <ul className="menu">
              <li>&copy; Kyoko AI Technologies. All rights reserved</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>


  );
};

export default Footer;
