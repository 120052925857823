import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProfile, getCreationGallery, getPurchaseOptions } from "../../api";

function replaceOrInsertObjectInList(list, key, newValue) {
  // Find the index of the object with the matching key
  const index = list.findIndex(obj => obj.key === key);

  // If found, replace the object at the found index
  if (index !== -1) {
    list[index] = newValue;
  } else {
    list.push(newValue);
  }

  return list;
}


export const onGetProfile = createAsyncThunk(
  "profile/onGetProfile",
  async (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const messages = await getProfile(token);
    return messages;
  }
);

export const onGetCreationGallery = createAsyncThunk(
  "profile/onGetCreationGallery",
  async (page, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const galleryPage = await getCreationGallery(token, page);
    return galleryPage;
  }
);

export const onSetGalleryDisplayPage = createAsyncThunk(
  "profile/onSetGalleryDisplayPage",
  async (page, { getState }) => {
    return page;
  }
);


export const onGetPurchaseOptions = createAsyncThunk(
  "profile/onGetPurchaseOptions",
  async (_, { getState }) => {
    // const state = getState();
    // const token = state.auth.token;
    const purchaseItems = await getPurchaseOptions();
    return purchaseItems;
  }
);


const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profile: { tokens: {} },

    isFetchingProfile: false,
    gallery: {
      inited: false,
      total_pages: 0,
      display_page: 0,
      pages: []
    },
    isFetchingGalleryPage: false,
    purchaseOptions: [],
    isFetchingPurchaseOptions: false
  },
  reducers: {},
  extraReducers: (builder) => {


    builder.addCase(
      onGetProfile.pending,
      (state) => {
        state.isFetchingProfile = true;
      }
    );
    builder.addCase(onGetProfile.fulfilled, (state, { payload }) => {
      state.profile = payload
      state.isFetchingProfile = false;
    });


    builder.addCase(
      onGetCreationGallery.pending,
      (state) => {
        state.isFetchingGalleryPage = true;
      }
    );
    builder.addCase(onGetCreationGallery.fulfilled, (state, { payload }) => {
      state.gallery.total_pages = payload.total_pages
      state.gallery.pages = replaceOrInsertObjectInList(state.gallery.pages, payload.page, {
        page: payload.page,
        content: payload.content
      });
      state.gallery.display_page = payload.page
      state.gallery.inited = true;
      // state.profile = payload
      state.isFetchingGalleryPage = false;
    });




    builder.addCase(
      onSetGalleryDisplayPage.pending,
      (state) => {
        state.isFetchingGalleryPage = true;
      }
    );
    builder.addCase(onSetGalleryDisplayPage.fulfilled, (state, { payload }) => {
      state.gallery.display_page = payload
      state.isFetchingGalleryPage = false;
    });



    builder.addCase(
      onGetPurchaseOptions.pending,
      (state) => {
        state.isFetchingPurchaseOptions = true;
      }
    );
    builder.addCase(onGetPurchaseOptions.fulfilled, (state, { payload }) => {
      state.purchaseOptions = payload
      state.isFetchingPurchaseOptions = false;
    });


  },
});

export default profileSlice.reducer;
