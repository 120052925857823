import React, { useState, useEffect } from 'react';
import { Input, InputGroup, Form, FormGroup } from "reactstrap";

import DownloadButton from './CreationDownloadButton';
import ThumbnailImage from '../assets/images/building5.jpeg'

import { useErrorHandling } from './ErrorHandling';


const Download = ({ code }) => {

    const [inputValue, setInputValue] = useState('');
    const [preview, setPreview] = useState(ThumbnailImage);

    const { showError, ErrorAlert } = useErrorHandling();

    useEffect(() => {
        if (code) {
            setInputValue(code); // Set the input value if the code exists in the URL
            setPreview(`/api/library/preview/${code}`)

            // setTimeout(() => {
            //     if (downloadButtonRef.current) {
            //         downloadButtonRef.current.click();
            //     }
            // }, 1000);
        }
    }, [code]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const isEmpty = !event.target.code.value.trim();
        if (isEmpty) {
            showError("No code supplied, please enter a valid code.")
            return;
        }

        event.target.reset();
    };

    return (<div className="text-center hero my-5">
        <h1 className="mb-4">Enter the story code below to download!</h1>

        <img src={preview} style={{ width: "75%" }} alt="Story to download preview"></img>
        <Form className="message-input-field__form" onSubmit={handleSubmit}>
            <FormGroup row>
                <InputGroup className="message-input-field__input-group">
                    <Input
                        className="message-input-field__input"
                        style={{ height: "auto" }}
                        type="text"
                        placeholder="XYZ1"
                        name="code"
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                    />
                    <DownloadButton code={inputValue} label={"Download"} showError={showError}></DownloadButton>
                </InputGroup>
            </FormGroup>
        </Form>

        <ErrorAlert />


    </div>
    );
};

export default Download;