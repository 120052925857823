import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Container, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";

import StudioGallery from '../components/Studio/StudioGallery';
import ConfirmationModal from '../components/Studio/ConfirmationModal';
import PurchaseTokensModal from '../components/Studio/PurchaseTokensModal';
import { selectProfile } from "../redux/profile/selectors";
import { startStoryCreation } from '../api';
import { selectAuthToken, selectIsAuthenticated } from '../redux/auth/selectors';
import { selectIsFetchingStudioCreations, selectStudioCreations } from '../redux/studio/selectors';
import { onGetStudioCreations } from '../redux/studio/slice';
import Loading from '../components/Loading';
import { onGetProfile } from '../redux/profile/slice';
import SignInModal from '../components/SignInModal';

const Studio = () => {

    const isAuthenticated = useSelector(selectIsAuthenticated);

    const profile = useSelector(selectProfile);
    const authToken = useSelector(selectAuthToken);
    const creations = useSelector(selectStudioCreations);
    const isFetchingCreations = useSelector(selectIsFetchingStudioCreations);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

    const toggleConfirmationModal = () => setIsConfirmationModalOpen(!isConfirmationModalOpen);
    const togglePurchaseModal = () => setIsPurchaseModalOpen(!isPurchaseModalOpen);
    const toggleSignInModal = () => setIsSignInModalOpen(!isSignInModalOpen);

    useEffect(() => {
        dispatch(onGetStudioCreations());
        dispatch(onGetProfile());
    }, [dispatch])


    const handleCreateNew = () => {
        if (!isAuthenticated) {
            toggleSignInModal()

            return;
        }

        // Get user available tokens from state
        if (profile.tokens.tokens > 0) {
            // Open modal to confirm token usage
            toggleConfirmationModal();
            // Open modal that says by continuing you agree to whatever and will consume one token.
            // User must agree which then navigates to creation studio
        } else {
            // Modal pops up saying you must purchase tokens with a link to the /pricing page
            togglePurchaseModal();
        }

    };


    const handleConfirm = async () => {
        // Logic to navigate to the creation studio after agreeing
        const creation_code = await startStoryCreation(authToken);
        toggleConfirmationModal();
        history.push(`/studio/story/${creation_code}`);
        // navigateToStoryCreation();
        // For example: history.push('/creation-studio');
    };

    const handleEditCreation = (creationId) => {
        history.push(`/studio/story/${creationId.toUpperCase()}`);
        // Logic to navigate to the edit page for the specific creation
        // For example: history.push(`/edit/${creationId}`);
    };

    return (
        <Container>
            <h1>Studio</h1>
            <Col className='gallery-container'>
                {isFetchingCreations && (
                    <Loading>Fetching Creations...</Loading>
                )}
                {!isFetchingCreations &&
                    (<StudioGallery
                        creations={creations}
                        onCreateNew={handleCreateNew}
                        onEditCreation={handleEditCreation}
                    />)
                }
            </Col>
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                toggle={toggleConfirmationModal}
                onConfirm={handleConfirm}
            />
            <PurchaseTokensModal
                isOpen={isPurchaseModalOpen}
                toggle={togglePurchaseModal}
            />
            <SignInModal
                isOpen={isSignInModalOpen}
                toggle={toggleSignInModal}
            />
        </Container>
    );
};

export default Studio;
